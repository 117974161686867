import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Container, Grid as ChakraGrid, GridItem, useBreakpointValue, VStack, Skeleton, Box } from '@chakra-ui/react'
import { ComposableTasticWrapper } from 'composable/components/composable-tastic-wrapper'
import { MegaMenuDetailsBar } from 'composable/components/mega-menu/MegaMenuDetailsBar'
import { convertToRows, getGridItemSize } from 'composable/helpers/utils/grid-utils'
import { useAccountCT } from 'frontastic/contexts/accountCTContext'
import { Errors } from './errors'
import { CellConfiguration, PageDataResponse, PagePreviewDataResponse, Tastic, TasticRegistry } from './types'

const renderTastics = (
  layoutElement: ReturnType<typeof convertToRows>[0][0],
  tastics: TasticRegistry,
  currentHighlight: string | undefined,
  data: PageDataResponse,
  rowIndex?: number,
) =>
  layoutElement.tastics.map((t: Tastic) => (
    <ComposableTasticWrapper
      tastics={tastics}
      key={t.tasticId}
      data={t}
      dataSources={data.data.dataSources}
      pageFolder={data.pageFolder}
      highlight={currentHighlight === t.tasticId}
      isMobileDevice={data?.isMobileDevice}
      rowIndex={rowIndex}
    />
  ))

const renderGridItems = (
  row: ReturnType<typeof convertToRows>[0],
  tastics: TasticRegistry,
  getResponsiveGridItemSpanValue: (size: number) => number,
  shouldDisplay: (config: CellConfiguration) => boolean,
  currentHighlight: string | undefined,
  data: PageDataResponse,
  rowIndex?: number,
) =>
  row.map((layoutElement) => {
    if (!shouldDisplay(layoutElement.configuration)) return null
    return (
      <GridItem
        key={layoutElement.layoutElementId}
        className={layoutElement.tastics[0]?.tasticType}
        gridColumn={`span ${getResponsiveGridItemSpanValue(layoutElement.configuration.size)}`}
        sx={{ '> div': { height: '100%', minHeight: 'auto' } }}
      >
        {renderTastics(layoutElement, tastics, currentHighlight, data, rowIndex)}
      </GridItem>
    )
  })

interface SectionGridProps {
  as: 'header' | 'main' | 'footer'
  rows: ReturnType<typeof convertToRows>
  tastics: TasticRegistry
  getResponsiveGridItemSpanValue: (size: number) => number
  shouldDisplay: (config: CellConfiguration) => boolean
  currentHighlight?: string
  data: PageDataResponse
}

const SectionGrid: React.FC<SectionGridProps> = ({
  as,
  rows,
  tastics,
  getResponsiveGridItemSpanValue,
  shouldDisplay,
  currentHighlight,
  data,
}) => (
  <VStack as={as} spacing={0}>
    {rows.map((row, i) => (
      <ChakraGrid key={i} w="full" gap="20px" gridTemplateColumns="repeat(12, 1fr)">
        {renderGridItems(row, tastics, getResponsiveGridItemSpanValue, shouldDisplay, currentHighlight, data, i)}
      </ChakraGrid>
    ))}
  </VStack>
)

export function FrontasticRenderer({
  data,
  tastics = {},
  gridClassName,
  wrapperClassName,
  currentHighlight,
}: {
  data: PageDataResponse & PagePreviewDataResponse
  tastics: TasticRegistry
  gridClassName?: string
  wrapperClassName?: string
  currentHighlight?: string
}) {
  const router = useRouter()
  const headerSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.head?.layoutElements),
    [data?.page?.sections?.head],
  )
  const mainSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.main?.layoutElements),
    [data?.page?.sections?.main],
  )
  const footerSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.footer?.layoutElements),
    [data?.page?.sections?.footer],
  )

  const getResponsiveGridItemSpanValue = useBreakpointValue({
    base: (originalSize: number) => getGridItemSize({ viewport: 'mobile', originalSize }),
    md: (originalSize: number) => getGridItemSize({ viewport: 'tablet', originalSize }),
    lg: (originalSize: number) => getGridItemSize({ viewport: 'desktop', originalSize }),
  })

  const shouldDisplay = useBreakpointValue({
    base: (config: CellConfiguration) => config.mobile,
    md: (config: CellConfiguration) => config.tablet,
    lg: (config: CellConfiguration) => config.desktop,
  })

  const { loggedIn, accountLoading: isAccountLoading } = useAccountCT()

  const DASHBOARD_ACCOUNT_PATH = '/account/dashboard'
  const STATIC_REWARDS_PATH = '/static/rewards'
  const STATIC_CUSTOMER_SERVICE_PATH = '/static/customer-service'
  const CHECKOUT_PATH = '/checkout'

  useEffect(() => {
    if (loggedIn && !isAccountLoading && router.asPath.includes(STATIC_REWARDS_PATH)) {
      router.replace(`${DASHBOARD_ACCOUNT_PATH}#rewards`, undefined, { shallow: false })
    }
  }, [loggedIn, isAccountLoading, router.asPath])

  const [showAccountDashboard, setShowAccountDashboard] = useState(false)
  const timerRef = useRef(null)
  const isReady = router.isReady
  useEffect(() => {
    if (isReady) {
      const currentPath = router.asPath
      if (router.asPath.includes('#')) {
        clearTimeout(timerRef.current)
        const id = router.asPath.split('#')[1]
        timerRef.current = setTimeout(() => {
          const element = document.getElementById(id)
          if (element && router.asPath.includes(STATIC_CUSTOMER_SERVICE_PATH)) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
            window.history.replaceState(null, '', `#${id}`)
          } else if (!router.asPath.includes(STATIC_REWARDS_PATH)) {
            router.replace(`#${id}`, undefined, { shallow: true }).then(() => {
              setShowAccountDashboard(true)
            })
          }
        }, 400)
      } else if (currentPath === DASHBOARD_ACCOUNT_PATH) {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(async () => {
          router.replace(`${currentPath}#profile`, undefined, { shallow: true })
        }, 400)
      }
      return () => {
        clearTimeout(timerRef.current)
      }
    }
  }, [router.asPath, isReady])

  const isContentShouldShown =
    ((isAccountLoading || loggedIn) && router.asPath.includes(STATIC_REWARDS_PATH)) ||
    (!showAccountDashboard && router.asPath.includes(DASHBOARD_ACCOUNT_PATH))

  return (
    <Container
      maxWidth={'full'}
      padding={0}
      style={{ display: 'flex', minHeight: '100vh' }}
      flexDirection={'column'}
      sx={{ main: { flex: '1' } }}
    >
      {process && process.env.NODE_ENV !== 'production' && <Errors />}

      <SectionGrid
        as="header"
        rows={headerSectionRows}
        tastics={tastics}
        getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
        shouldDisplay={shouldDisplay}
        currentHighlight={currentHighlight}
        data={data}
      />
      {!router.asPath.startsWith(CHECKOUT_PATH) && (
        <Box width="100%" bg="brand.muted">
          <MegaMenuDetailsBar />
        </Box>
      )}
      {isContentShouldShown ? (
        <Container maxW="1500px" marginY="50px">
          <Skeleton height="200px" />
        </Container>
      ) : (
        <SectionGrid
          as="main"
          rows={mainSectionRows}
          tastics={tastics}
          getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
          shouldDisplay={shouldDisplay}
          currentHighlight={currentHighlight}
          data={data}
        />
      )}

      <SectionGrid
        as="footer"
        rows={footerSectionRows}
        tastics={tastics}
        getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
        shouldDisplay={shouldDisplay}
        currentHighlight={currentHighlight}
        data={data}
      />
    </Container>
  )
}
