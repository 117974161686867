import * as React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Log, LogError } from 'helpers/errorLogger'
import { isDevelopment } from 'helpers/utils/environment'

const getErrorMessage = (error: LogError) => {
  if (typeof error?.data[0] === 'string') {
    return error.data[0]
  }

  if (typeof error?.data[0]?.message === 'string') {
    return error.data[0].message
  }

  try {
    return JSON.stringify(error?.data[0])
  } catch (e) {
    return 'Unprintable error, check console'
  }
}

export function Errors() {
  const [errors, setErrors] = React.useState(Log.getErrors())
  const [open, setOpen] = React.useState(!!Log.getErrors())

  // There is some complexity here:
  //
  // If we just append the incoming error to the state we try to update the
  // state while another componet is rendering, because the errors are appended
  // as a side effect.
  //
  // If we delay error recoding as done now using setTimeout() we will loose
  // errors happening exactly in parallel.
  //
  // If somebody has a better solution for this, please fix:
  Log.setErrorLogger((error: LogError) => {
    if (typeof window !== 'undefined') {
      window.setTimeout(() => {
        setErrors([error, ...errors].slice(0, 5))
        setOpen(true)
      }, 0)
    } else {
      setErrors([error, ...errors].slice(0, 5))
      setOpen(true)
    }
  })

  const isSM = useBreakpointValue({ base: false, sm: true })

  if (!errors.length || !open || !isDevelopment()) {
    return null
  }

  return (
    <Box position="relative" zIndex={10}>
      <Box bg="rgba(0,0,0,0.5)" position="fixed" inset={0} zIndex={10} overflowY="auto" textAlign="center">
        <Box
          as="span"
          display={{ base: 'hidden', sm: 'inline-block' }}
          aria-hidden="true"
          style={isSM ? { height: '100vh', verticalAlign: 'middle' } : null}
        >
          &#8203;
        </Box>
        <Box
          position="relative"
          display="inline-block"
          overflow="hidden"
          borderRadius="lg"
          bg="white"
          textAlign="left"
          transition="all"
          shadow="xl"
          my={{ sm: 8 }}
          w={{ sm: 'full' }}
          maxW={{ sm: '3xl' }}
          style={isSM ? { verticalAlign: 'middle' } : { verticalAlign: 'bottom' }}
        >
          <Box bg="white" px={4} pb={4} pt={5}>
            <Box display={{ sm: 'flex' }} alignItems={{ sm: 'flex-start' }}>
              <Box
                mx={{ base: 'auto', sm: 0 }}
                display="flex"
                h={{ base: 12, sm: 10 }}
                w={{ base: 12, sm: 10 }}
                flexShrink={0}
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
                bg="red.100"
              >
                <Icon as={ExclamationIcon} boxSize={6} color="red.600" aria-hidden="true" />
              </Box>
              <Box textAlign={{ base: 'center', sm: 'left' }} ml={{ sm: 4 }} mt={{ base: 3, sm: 0 }}>
                <Text as="h3" fontSize="lg" fontWeight="medium" color="gray.900">
                  Errors communicating with the API hub
                </Text>
                <Box mt={2}>
                  <Text fontSize="sm" color="gray.500">
                    Some errors occured when communicating with the API hub. Check the sandbox logs of your Frontastic
                    CLI for details and possibly a stack trace (press <kbd>s</kbd> there) and find the error messages
                    either in the browser console (press <kbd>F12</kbd> in the browser) or the last five below:
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box as="dl" mt={6}>
              <Accordion allowToggle>
                {errors.map((error, errorIndex) => (
                  <AccordionItem borderBottom="0!important" borderTopWidth={errorIndex ? 1 : 0} key={errorIndex}>
                    <h2>
                      <AccordionButton>
                        <Text as="span" flex={1} textAlign="left" fontWeight="medium" color="gray.900">
                          <strong>{error.type}:</strong> {getErrorMessage(error)}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box borderRadius="sm" bg="gray.100" p={2}>
                        <Box as="code" whiteSpace="pre-wrap" fontSize="sm">
                          {JSON.stringify(error.data, null, 2)}
                        </Box>
                      </Box>
                      {
                        // @ts-ignore
                        error.data[1]?.frontasticRequestId && (
                          <p>
                            Frontastic Request ID:{' '}
                            <pre>
                              {
                                // @ts-ignore
                                error.data[1].frontasticRequestId
                              }
                            </pre>
                          </p>
                        )
                      }
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          </Box>
          <Box
            bg="gray.50"
            px={{ base: 4, sm: 6 }}
            py={3}
            display={{ sm: 'flex' }}
            flexDirection={{ sm: 'row-reverse' }}
          >
            <Button
              bg="red.500"
              _hover={{ bg: 'red.600' }}
              _focus={{ bg: 'red.600' }}
              type="button"
              onClick={() => setOpen(false)}
              w={{ base: 'full', sm: 'auto' }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
